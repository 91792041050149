import "./carousel.css";

const slides = [
  "/images/IMG_3061.jpg",
  "/images/_DSC0103.jpg",
  "/images/_DSC0152.jpg",
  "/images/3O2A8591.jpg",
  "/images/3O2A8615.jpg",
  "/images/3O2A9218.jpg",
  "/images/IMG_2903.jpg",
  "/images/IMG_2879.jpg",
  "/images/IMG_2954.jpg",
];

export const Carousel = () => {
  return (
    <div className="overflow-y-hidden overflow-x-hidden sm:!mt-[-80px] !mt-[-10px] relative sm:h-[550px] sm:min-h-[550px] h-[300px] min-h-[300px] flex items-center">
      <img loading="lazy" src="/icons/e1.png"
        alt="elipsis"
        className="w-[100%] carousel absolute sm:top-[5%] top-[5%] z-[4] hover:z-[2]"
      />
      <div className="caro-container">
        <div className="slider">
          <div className="slide-track">
            {slides.map((img, index) => (
              <div className="slide" key={index}>
                <img loading="lazy" src={img} alt="" className="w-full h-full object-cover"/>
              </div>
            ))}
          </div>
        </div>
      </div>
      <img loading="lazy" src="/icons/e1.png"
        alt="elipsis"
        className="hover:rotate-180 w-[100%] rotate-180 absolute sm:bottom-[-16%] bottom-[6%] z-[4]"
      />
    </div>
  );
};
