export default function AboutUs() {
  return (
    <main className="text-[#191919]">
      <section className="flex flex-col items-center justify-center my-6 sm:my-12">
        <div className="w-11/12 sm:w-5/6">
          <h1 className="font-bold leading-[44.96px] sm:leading-[89.92px] text-[36px] sm:text-[70px] max-w-4xl">
            We are celebrating Afrobeat Culture at{" "}
            <span className="text-[#009A00] font-crimson relative inline-block">
              Felabration{" "}
              <img
                loading="lazy"
                alt="star"
                src="/icons/star.svg"
                className="absolute top-[-7px] right-[-18px] sm:top-[-19px] w-8 sm:w-auto sm:right-[-50px]"
              />
            </span>
          </h1>
        </div>
        <div className="sm:flex grid grid-cols-2 gap-5 sm:gap-7 my-16 w-11/12 sm:w-5/6">
          <div>
            <img
              loading="lazy"
              src="/images/img-2.jpg"
              className="rounded-tl-[40px] rounded-br-[40px] h-[300px] w-[200px] object-cover"
              alt=""
            />
          </div>
          <div className="sm:pt-32 pt-28">
            <img
              loading="lazy"
              src="/images/BUSHMAN-CREATIVES-F21-0287.jpg"
              className="rounded-tl-[40px] rounded-br-[40px] h-[300px] w-[200px] object-cover"
              alt=""
            />
          </div>
          <div className="sm:pt-8 -mt-28 sm:mt-0">
            <img
              loading="lazy"
              src="/images/BUSHMAN-CREATIVES-F21-0003.jpg"
              className="rounded-tl-[40px] rounded-br-[40px] h-[300px] w-[200px] object-cover"
              alt=""
            />
          </div>
          <div className="sm:-mt-16">
            <img
              loading="lazy"
              src="/images/F22-SUN-0549.jpg"
              className="rounded-tl-[40px] rounded-br-[40px] h-[300px] w-[200px] object-cover"
              alt=""
            />
          </div>
          <div className="hidden sm:block">
            <img
              loading="lazy"
              src="/images/BUSHMAN-CREATIVES-F21-0188.jpg"
              className="rounded-tl-[40px] rounded-br-[40px] h-[300px] w-[200px] object-cover"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6">
          <h2 className="font-semibold text-[32px] sm:text-[64px]">
            Who we are
          </h2>
          <p className="text-[16px] sm:text-[20px]">
            Felabration is an annual festival honoring Nigeria's musical icon,
            Fela Anikulakpo Kuti. Conceived by Yeni Kuti in 1998, this
            celebration of Fela's life and music has been held annually, with
            the exception of 1999. Organized by the Felabration Organizing
            Committee, the event has evolved into a week-long musical
            extravaganza at the New Africa Shrine, attracting thousands
            worldwide. Recognized as an official tourist destination,
            Felabration receives major sponsorship from the Lagos State
            Government.
          </p>
          <p className="text-[16px] sm:text-[20px]">
            Over the years, the festival has featured renowned artists like Hugh
            Masekela, Femi Kuti, Lucky Dube, and others, showcasing its global
            appeal. Notably, in 2008, over 50 international artists joined
            forces under "African Express" to perform at Felabration. The event,
            held every October, includes diverse activities like 'The Fela
            Debates,' Afrobics Dance Competition, and Art Exhibitions, providing
            a vibrant week-long celebration of Fela's enduring influence as the
            creator of afrobeat and a social and political icon.
          </p>
        </div>
      </section>

      <section className="flex justify-center my-16">
        <div className="w-11/12 sm:w-5/6">
          <h2 className="text-[32px] sm:text-[64px] font-semibold">Our team</h2>
          <div className="grid grid-cols-2 my-6 sm:grid-cols-3 gap-x-7 gap-y-7 sm:gap-y-16">
            {[
              {
                member: "Kachi Nwanya",
                image: "/committee/Kachi_Nwanya.jpeg",
                text: "Social Media and PR Committee, Licensing, Project Lead, Competition, Sponsorship Lead - FOC Member",
              },
              {
                member: "Matthew Ohio",
                image: "/committee/Matthew_Ohio.jpeg",
                text: "Concert committee",
              },

              {
                member: "Security Committee",
                image: "/committee/Security_Committee.jpeg",
                text: "Security Committee/Shrine Manager",
              },
              {
                member: "Sandra Oyewole",
                image: "/committee/sandra.jpg",
                text: "Head legal services, Art, Dance, Dress Fela competition and ladies webinar",
              },
              {
                member: "Jumoke Coker",
                image: "/committee/Jumoke_Coker.jpeg",
                text: "Hospitality Committee/FOC Member",
              },
              {
                member: "Nike Nedum",
                image: "/committee/Nike_Nedum.jpeg",
                text: "Senior Secondary School Debate Coordinator/FOC Member",
              },
              {
                member: "Femi Odebunmi",
                image: "/committee/Femi_Odebunmi.jpeg",
                text: "Symposium Coordinator/FOC Member",
              },
              {
                member: "Austine Emenike Iroegbu",
                image: "/committee/Austine_Emenike_Iroegbu.jpeg",
                text: "Accountant/FOC Member",
              },
              {
                member: "Ilunamien Isimeme Onoikhua",
                image: "/committee/Ilunamien_Isimeme_Onoikhua.jpeg",
                text: "Welfare, Security and Artist Committee/FOC Member",
              },
              {
                member: "Jide Bello",
                image: "/committee/Jide_Bello.png",
                text: "Felabration License Partners, FOC Exco",
              },
              {
                member: "Theo Lawson",
                image: "/committee/Theo_Lawson.jpeg",
                text: "Chairman, Felabration Organizing Committee",
              },
              {
                member: "Femi Kuti",
                image: "/committee/femi_kuti.png",
                text: "Head Security and FOC Exco",
              },
              {
                member: "Dotun Ransome Kuti",
                image: "/committee/dotun_ransome_kuti,.png",
                text: "Stage and Artist Management, FOC Exco",
              },
              {
                member: "Michael Okorie",
                image: "/committee/michael.jpg",
                text: "Project Manager",
              },
              {
                member: "Funke Kuti",
                image: "/committee/funke.jpg",
                text: "Head of Concert",
              },
            ].map(({ member, image, text }) => (
              <div className="text-center bg-black flex flex-col gap-2 sm:gap-5 w-full">
                <img loading="lazy" src={image} alt="team" />
                <div>
                  <div className="text-[16px] text-white sm:text-[32px] font-semibold">
                    {member}
                  </div>
                  <p className="text-[14px] text-white sm:text-[20px]">
                    {text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}
